import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "grey lighten-5 pa-5 my-5" },
    [
      _c("h3", { staticClass: "black--text" }, [_vm._v("Return carrier")]),
      !_vm.preferredCarrier
        ? _c("div", { staticClass: "text-dark mt-2" }, [
            _vm._v(
              "The partner has not yet selected their carrier preferences on zDirect.\nPlease wait before adding configuration for delivery carriers."
            ),
          ])
        : _vm._e(),
      _vm.preferredCarrier
        ? _c("div", { staticClass: "text-dark mt-2" }, [
            _vm._v(
              "Add configuration for return carrier. For any carriers not listed,\ncontact the OrderX team for overrides or exceptions.\nNote that you can only configure one return carrier at a time."
            ),
          ])
        : _vm._e(),
      _vm.configuredReturnCarrierSelection && !_vm.showAddOrEditReturnCarrier
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                VCard,
                {
                  staticClass: "pa-4 rounded mt-4",
                  attrs: { outlined: "", tile: "", rounded: "sm" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-space-between align-center",
                    },
                    [
                      _c("div", [
                        _vm.configuredReturnCarrier
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-subtitle-2 font-weight-bold mr-2 black--text",
                                },
                                [_vm._v("Return carrier:")]
                              ),
                              _c("span", { staticClass: "text-body-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.configuredReturnCarrierSelection.carrier
                                      .name
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.configuredReturnParcelPerformMapping
                          ? _c("div", { staticClass: "mt-2" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-subtitle-2 font-weight-bold mr-2 black--text",
                                },
                                [_vm._v("Track & trace reference:")]
                              ),
                              _c("span", { staticClass: "text-body-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.configuredReturnCarrierSelection
                                      .parcelPerformReference.reference
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            VBtn,
                            {
                              staticClass:
                                "mt-5 mb-6 ml-2 text-none rounded font-weight-bold",
                              attrs: { outlined: "", color: "black" },
                              on: { click: _vm.onReturnCarrierEditClick },
                            },
                            [_vm._v("Edit")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.configuredReturnCarrierSelection && !_vm.showAddOrEditReturnCarrier
        ? _c(
            VBtn,
            {
              staticClass: "mt-5 white--text text-none",
              attrs: { color: "black", loading: _vm.isReturnCarrierSubmitting },
              on: {
                click: function ($event) {
                  _vm.showAddOrEditReturnCarrier =
                    !_vm.showAddOrEditReturnCarrier
                },
              },
            },
            [_vm._v("Add return carrier configuration")]
          )
        : _vm._e(),
      _vm.showAddOrEditReturnCarrier
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "mt-2 row" },
                [
                  _c(VSelect, {
                    staticClass: "ml-4",
                    style: { maxWidth: "40%" },
                    attrs: {
                      label: "Select return carrier",
                      items: _vm.carriersList,
                    },
                    on: { change: _vm.onReturnCarrierChange },
                    model: {
                      value: _vm.selectedReturnCarrier,
                      callback: function ($$v) {
                        _vm.selectedReturnCarrier = $$v
                      },
                      expression: "selectedReturnCarrier",
                    },
                  }),
                  _c(VSelect, {
                    staticClass: "ml-4",
                    style: { maxWidth: "40%" },
                    attrs: {
                      label: "Select track & trace reference",
                      items: _vm.parcelPerformMappingsList,
                    },
                    model: {
                      value: _vm.selectedReturnParcelPerformMapping,
                      callback: function ($$v) {
                        _vm.selectedReturnParcelPerformMapping = $$v
                      },
                      expression: "selectedReturnParcelPerformMapping",
                    },
                  }),
                ],
                1
              ),
              _c(
                VBtn,
                {
                  staticClass:
                    "mt-5 mb-6 ml-2 text-none rounded font-weight-bold",
                  class: {
                    "btn-disabled": _vm.shouldDisableReturnCarrierSubmit,
                  },
                  staticStyle: { "border-width": "2px" },
                  attrs: {
                    outlined: "",
                    color: "black",
                    disabled: _vm.shouldDisableReturnCarrierSubmit,
                    loading: _vm.isReturnCarrierSubmitting,
                  },
                  on: { click: _vm.addNewReturnCarrier },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }