// NEW - this is needed for RLG feature

import axios from 'axios';
import { pipe, path, prop } from 'ramda';
import {
  decamelizeRequestHandler,
  camelizeResponseHandler,
} from '@/api/api-mutations';
import ErrorHandler from 'Api/api-errors-handler';
import { SelectedReturnServiceProviderT } from '@/api/models/SelectedReturnServiceProviders';

const selectedReturnServiceProvidersApi = axios.create({
  baseURL: '/api/merchants',
});
selectedReturnServiceProvidersApi.interceptors.request.use(
  decamelizeRequestHandler
);
selectedReturnServiceProvidersApi.interceptors.response.use(
  pipe(camelizeResponseHandler, path(['data'])),
  ErrorHandler
);

export function fetchSelecterReturnServiceProviders(
  merchantId: string
): Promise<SelectedReturnServiceProviderT[]> {
  return (
    selectedReturnServiceProvidersApi
      .get(`${merchantId}/selected-return-service-providers`)
      // @ts-ignore
      .then(prop('items'))
      .catch((err) => {
        return err.response?.status === 404 ? [] : Promise.reject(err);
      }) as Promise<SelectedReturnServiceProviderT[]>
  );
}
