export enum RlgStatusT {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DISABLED = 'DISABLED',
}

export enum StatusType {
  VALID = 'VALID',
  FAILED = 'FAILED',
}

export interface ConfiguredCountryT {
  salesChannelId: string;
  countryCode: string;
  rlgStatus: RlgStatusT;
}

interface StatusT {
  value: StatusType;
  message: string;
}

export interface SelectedReturnServiceProviderT {
  id: string;
  name: string;
  status: StatusT;
  configuredCountries: ConfiguredCountryT[];
}
