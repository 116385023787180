<template lang="pug">
  div.root
    v-container
      v-row
        v-col.body-1(cols="3") Business Partner Id
        v-col.mono {{ merchantId }}

      v-row
        v-col.body-1(cols="3") Sales Channel Id
        v-col.mono {{ channelId }}

      v-row
        v-col.body-1(cols="3") Status
        v-col
          v-chip(
            :color="isLive ? 'green' : 'grey'"
            text-color="white"
            small
          ) {{ isLive ? 'ONLINE' : 'OFFLINE' }}

      div.mt-8
        v-btn(
          v-if="isPutLiveBtnDisplayed"
          :disabled="!isPutLiveBtnEnabled"
          depressed
          :loading="isPutLiveInProgress"
          color="primary"
          @click="putOnline"
        )
          | Put
          span.mx-1.font-weight-light {{ countryName }}
          | Online

        v-btn(
          v-if="isTakeOfflineBtnDisplayed"
          depressed
          :loading="isTakeOfflineInProgress"
          color="error"
          @click="isModalVisible = true"
        )
          | Take
          span.mx-1.font-weight-light {{ countryName }}
          | Offline

      ShipmentServices(
        v-if="showOutboundSolution"
        :merchant-name="merchantName"
        :country-name="countryName"
        :shipment-data="shipmentData"
        :on-shipment-change="onShipmentChange"
        :is-zfs-channel="isZfsChannel"
        :is-hybrid-channel="isHybridChannel"
        :is-zfs-channel-launched="isZfsChannelLaunched"
        :is-loading="isShipmentDataLoading"
        :selected-return-service-provider="selectedReturnServiceProvider"
        :is-selected-return-service-providers-loading="isSelectedReturnServiceProvidersLoading"
      )

    //- Take Online confirmation dialog
    v-dialog(v-model="isPutLiveModalVisible" width="550")
      v-card
        mpAlert.text-h6(type="warning") Warning!

        v-card-text
          div
            | There are two fulfillment types (Partner Fulfilment, ZFS) that are
            | in LAUNCHED status in {{ countryName }}.
            br
            | Setting the sales channel to ONLINE will put both fulfilment types to Online too.
            br
            | Please make sure before you proceed with this.

        v-card-actions
          v-spacer
          v-btn(text @click="isPutLiveModalVisible = false") Cancel

          v-btn(
            color="primary"
            depressed
            @click="putOnline(true)"
          ) Put Online both fulfillment types

    //- Take Offline confirmation dialog
    v-dialog(v-model="isModalVisible" width="550")
      v-card
        mpAlert.text-h6(type="warning") Warning!

        v-card-text
          div(v-if="isBothContractsOnline")
            | There are two fulfillment types (Partner Fulfilment, ZFS) that are in ONLINE status in {{countryName}}.
            br
            | Setting the sales channel to OFFLINE will put both fulfilment types to Offline too.
            br
            | Please make sure you want proceed with this.
          div(v-else)
            | Are you sure you want to take {{merchantName}}'s operations in {{countryName}} offline?
          br

          div The provided reason will be visible to the partner on zDirect.

        v-card-text
          v-textarea(
            filled
            autofocus
            :error-messages="errorMessages"
            label="Offline reason"
            :hint="'Please specify at least '+minWordsCount+' words'"
            :validate-on-blur="true"
            :rules="[isReasonValid]"
            v-model="reasonForOffline"
            @blur="isInputDirty = true"
          )

        v-card-actions
          v-spacer
          v-btn(text @click="resetState") Cancel

          v-btn(
            color="primary"
            depressed
            @click="setOffline"
            :disabled="!isReasonValid"
          ) Take {{ countryName }} Offline
</template>

<script lang="ts">
import Vue from 'vue';
import ShipmentServices from './shipment-services.vue';
import { ShipmentServiceT } from 'Api/models/ShipmentService';
import { SelectedReturnServiceProviderT } from '@/api/models/SelectedReturnServiceProviders';

export const REASON_MIN_WORDS_NUMBER = 3;

export default Vue.extend({
  components: { ShipmentServices },
  props: {
    merchantId: { type: String, required: true },
    merchantName: { type: String, required: true },
    channelId: { type: String, required: true },
    countryName: { type: String, required: true },
    isLive: { type: Boolean, required: true },
    isPutLiveBtnDisplayed: { type: Boolean, required: true },
    isPutLiveBtnEnabled: { type: Boolean, required: true },
    isTakeOfflineBtnDisplayed: { type: Boolean, required: true },
    isBothContractsOnline: { type: Boolean },
    requestGoOnlineConfirmation: { type: Boolean },
    onPutLiveCb: { type: Function, required: true },
    onTakeOfflineCb: { type: Function, required: true },
    shipmentData: { type: Object as () => ShipmentServiceT, required: false },
    onShipmentChange: { type: Function, required: true },
    isZfsChannel: { type: Boolean, required: true },
    isHybridChannel: { type: Boolean, required: true },
    isZfsChannelLaunched: { type: Boolean, required: true },
    isShipmentDataLoading: { type: Boolean, required: true },
    selectedReturnServiceProvider: {
      type: Object as () => SelectedReturnServiceProviderT,
      required: false,
    },
    isSelectedReturnServiceProvidersLoading: { type: Boolean, required: true },
  },

  data() {
    return {
      minWordsCount: REASON_MIN_WORDS_NUMBER,
      isModalVisible: false,
      isPutLiveModalVisible: false,
      isInputDirty: false,
      reasonForOffline: '',
      isPutLiveInProgress: false,
      isTakeOfflineInProgress: false,
    };
  },
  computed: {
    isReasonValid() {
      return (
        this.reasonForOffline.trim().split(/\s+/).length >=
        REASON_MIN_WORDS_NUMBER
      );
    },
    errorMessages() {
      return !this.isInputDirty || this.isReasonValid
        ? []
        : `Please don't leave this empty, minimum of ${REASON_MIN_WORDS_NUMBER} words required.`;
    },
    showOutboundSolution() {
      return !this.isZfsChannel;
    },
  },

  methods: {
    putOnline(wasConfirmationGiven = false) {
      if (wasConfirmationGiven === true) {
        this.isPutLiveModalVisible = false;
        this.isPutLiveInProgress = true;
        this.onPutLiveCb();
        return;
      }
      if (this.requestGoOnlineConfirmation) {
        this.isPutLiveModalVisible = true;
        return;
      }
      this.isPutLiveInProgress = true;
      this.onPutLiveCb();
      return;
    },
    setOffline() {
      if (!this.isReasonValid) {
        this.isInputDirty = true;
        return;
      }
      this.isTakeOfflineInProgress = true;
      this.onTakeOfflineCb(this.reasonForOffline);
      this.resetState();
    },
    resetState() {
      this.isModalVisible = false;
      this.isInputDirty = false;
      this.reasonForOffline = '';
    },
  },
});
</script>

<style lang="scss" scoped>
.mono {
  font-family: monospace;
}
</style>
