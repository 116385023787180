import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c(
        VContainer,
        [
          _c(
            VRow,
            [
              _c(VCol, { staticClass: "body-1", attrs: { cols: "3" } }, [
                _vm._v("Business Partner Id"),
              ]),
              _c(VCol, { staticClass: "mono" }, [
                _vm._v(_vm._s(_vm.merchantId)),
              ]),
            ],
            1
          ),
          _c(
            VRow,
            [
              _c(VCol, { staticClass: "body-1", attrs: { cols: "3" } }, [
                _vm._v("Sales Channel Id"),
              ]),
              _c(VCol, { staticClass: "mono" }, [
                _vm._v(_vm._s(_vm.channelId)),
              ]),
            ],
            1
          ),
          _c(
            VRow,
            [
              _c(VCol, { staticClass: "body-1", attrs: { cols: "3" } }, [
                _vm._v("Status"),
              ]),
              _c(
                VCol,
                [
                  _c(
                    VChip,
                    {
                      attrs: {
                        color: _vm.isLive ? "green" : "grey",
                        "text-color": "white",
                        small: "",
                      },
                    },
                    [_vm._v(_vm._s(_vm.isLive ? "ONLINE" : "OFFLINE"))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _vm.isPutLiveBtnDisplayed
                ? _c(
                    VBtn,
                    {
                      attrs: {
                        disabled: !_vm.isPutLiveBtnEnabled,
                        depressed: "",
                        loading: _vm.isPutLiveInProgress,
                        color: "primary",
                      },
                      on: { click: _vm.putOnline },
                    },
                    [
                      _vm._v("Put"),
                      _c("span", { staticClass: "mx-1 font-weight-light" }, [
                        _vm._v(_vm._s(_vm.countryName)),
                      ]),
                      _vm._v("Online"),
                    ]
                  )
                : _vm._e(),
              _vm.isTakeOfflineBtnDisplayed
                ? _c(
                    VBtn,
                    {
                      attrs: {
                        depressed: "",
                        loading: _vm.isTakeOfflineInProgress,
                        color: "error",
                      },
                      on: {
                        click: function ($event) {
                          _vm.isModalVisible = true
                        },
                      },
                    },
                    [
                      _vm._v("Take"),
                      _c("span", { staticClass: "mx-1 font-weight-light" }, [
                        _vm._v(_vm._s(_vm.countryName)),
                      ]),
                      _vm._v("Offline"),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.showOutboundSolution
            ? _c("ShipmentServices", {
                attrs: {
                  "merchant-name": _vm.merchantName,
                  "country-name": _vm.countryName,
                  "shipment-data": _vm.shipmentData,
                  "on-shipment-change": _vm.onShipmentChange,
                  "is-zfs-channel": _vm.isZfsChannel,
                  "is-hybrid-channel": _vm.isHybridChannel,
                  "is-zfs-channel-launched": _vm.isZfsChannelLaunched,
                  "is-loading": _vm.isShipmentDataLoading,
                  "selected-return-service-provider":
                    _vm.selectedReturnServiceProvider,
                  "is-selected-return-service-providers-loading":
                    _vm.isSelectedReturnServiceProvidersLoading,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { width: "550" },
          model: {
            value: _vm.isPutLiveModalVisible,
            callback: function ($$v) {
              _vm.isPutLiveModalVisible = $$v
            },
            expression: "isPutLiveModalVisible",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                "mpAlert",
                { staticClass: "text-h6", attrs: { type: "warning" } },
                [_vm._v("Warning!")]
              ),
              _c(VCardText, [
                _c("div", [
                  _vm._v(
                    "There are two fulfillment types (Partner Fulfilment, ZFS) that are\nin LAUNCHED status in " +
                      _vm._s(_vm.countryName) +
                      "."
                  ),
                  _c("br"),
                  _vm._v(
                    "Setting the sales channel to ONLINE will put both fulfilment types to Online too."
                  ),
                  _c("br"),
                  _vm._v("Please make sure before you proceed with this."),
                ]),
              ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.isPutLiveModalVisible = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary", depressed: "" },
                      on: {
                        click: function ($event) {
                          return _vm.putOnline(true)
                        },
                      },
                    },
                    [_vm._v("Put Online both fulfillment types")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { width: "550" },
          model: {
            value: _vm.isModalVisible,
            callback: function ($$v) {
              _vm.isModalVisible = $$v
            },
            expression: "isModalVisible",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                "mpAlert",
                { staticClass: "text-h6", attrs: { type: "warning" } },
                [_vm._v("Warning!")]
              ),
              _c(VCardText, [
                _vm.isBothContractsOnline
                  ? _c("div", [
                      _vm._v(
                        "There are two fulfillment types (Partner Fulfilment, ZFS) that are in ONLINE status in " +
                          _vm._s(_vm.countryName) +
                          "."
                      ),
                      _c("br"),
                      _vm._v(
                        "Setting the sales channel to OFFLINE will put both fulfilment types to Offline too."
                      ),
                      _c("br"),
                      _vm._v("Please make sure you want proceed with this."),
                    ])
                  : _c("div", [
                      _vm._v(
                        "Are you sure you want to take " +
                          _vm._s(_vm.merchantName) +
                          "'s operations in " +
                          _vm._s(_vm.countryName) +
                          " offline?"
                      ),
                    ]),
                _c("br"),
                _c("div", [
                  _vm._v(
                    "The provided reason will be visible to the partner on zDirect."
                  ),
                ]),
              ]),
              _c(
                VCardText,
                [
                  _c(VTextarea, {
                    attrs: {
                      filled: "",
                      autofocus: "",
                      "error-messages": _vm.errorMessages,
                      label: "Offline reason",
                      hint:
                        "Please specify at least " +
                        _vm.minWordsCount +
                        " words",
                      "validate-on-blur": true,
                      rules: [_vm.isReasonValid],
                    },
                    on: {
                      blur: function ($event) {
                        _vm.isInputDirty = true
                      },
                    },
                    model: {
                      value: _vm.reasonForOffline,
                      callback: function ($$v) {
                        _vm.reasonForOffline = $$v
                      },
                      expression: "reasonForOffline",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    { attrs: { text: "" }, on: { click: _vm.resetState } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        color: "primary",
                        depressed: "",
                        disabled: !_vm.isReasonValid,
                      },
                      on: { click: _vm.setOffline },
                    },
                    [_vm._v("Take " + _vm._s(_vm.countryName) + " Offline")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }